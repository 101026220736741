import React from 'react';
//import logo from './logo.svg';
import './App.css';
import Cabinet from './cabinet/Cabinet';
import NavbarPage from './cabinet/Navbar';
//import { render } from '@testing-library/react';
import Enter from './cabinet/Enter';
import Footer from './cabinet/Footer'

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modalEnter: true,
      nameUser: localStorage.getItem('nameUser'),
      phoneUser: localStorage.getItem('phoneUser'),
      cardUser: localStorage.getItem('cardUser'),
      UserID: localStorage.getItem('UserID')

    };


  }

  // bitrEnter = 'https://club.teeal.pl/dat/bitrEnter.php';
  // createCont = 'https://club.teeal.pl/dat/createCont.php';
  // getStat = 'https://club.teeal.pl/dat/getStat.php';
  // getAllrefLid = 'https://club.teeal.pl/dat/getAllrefLid.php';
  // createNewLid = 'https://club.teeal.pl/dat/createNewLid.php';
  // writeToUser = 'https://club.teeal.pl/dat/writeToUser.php';

  //локал
  // bitrEnter = 'http://bonus-prod/v2/club-teeal/dat/bitrEnter.php';
  // createCont = 'http://bonus-prod/v2/club-teeal/dat/createCont.php';
  // getStat = 'http://bonus-prod/v2/club-teeal/dat/getStat.php';
  // getAllrefLid = 'http://bonus-prod/v2/club-teeal/dat/getAllrefLid.php';
  // createNewLid = 'http://bonus-prod/v2/club-teeal/dat/createNewLid.php';
  // writeToUser = 'http://bonus-prod/v2/club-teeal/dat/writeToUser.php';

  //для прода
  bitrEnter = './dat/bitrEnter.php';
  createCont = './dat/createCont.php';
  getStat = './dat/getStat.php';
  getAllrefLid = './dat/getAllrefLid.php';
  createNewLid = './dat/createNewLid.php';
  writeToUser = './dat/writeToUser.php';





  componentDidMount() {
    this.chekUser()
    // let UserID = localStorage.getItem('UserID');
    // let nameUser = localStorage.getItem('nameUser');
    // let phoneUser = localStorage.getItem('phoneUser');
    // let cardUser = localStorage.getItem('cardUser');
    // console.log('nameUser - ' + nameUser + ' phoneUser - ' + phoneUser);
    // this.setState({ nameUser: 'nameUser', 'phoneUser': phoneUser, 'cardUser': cardUser, 'UserID': UserID })

    //this.chekUser()
    // console.log('general-state');
    // console.log(this.state);

  }

  chekUser = () => {
    const UserID = localStorage.getItem('UserID');
    if (UserID) {

      // let nameUser = localStorage.getItem('nameUser');
      // let phoneUser = localStorage.getItem('phoneUser');
      // let cardUser = localStorage.getItem('cardUser');
      // console.log('nameUser' + nameUser + ' phoneUser' + phoneUser);
      // this.setState({ 'nameUser': nameUser, 'phoneUser': phoneUser, 'cardUser': cardUser, 'UserID': UserID })
    }
    else {
      // console.log('UserID')
      // console.log(UserID);
      this.setState({ modalEnter: false })
    }
  }

  updateData = (value) => {
    this.setState({ nameUser: value })
    // console.log('navbar state');
    // console.log(this.state);
  }

  enterModal = () => {
    if (!this.state.modalEnter) {

      return (<Enter
        updateData={this.updateData}
        bitrEnter={this.bitrEnter}
        createCont={this.createCont}
        writeToUser={this.writeToUser} />)
    }
  }

  render() {
    return (
      <div style={{ width: '100%' }}>
        <div>
          {/* <div style={{ width: '100%' }}> */}
          <NavbarPage
            nameUser={this.state.nameUser}
            phoneUser={this.state.phoneUser}
            cardUser={this.state.cardUser}
            UserID={this.state.UserID}
            createNewLid={this.createNewLid}
          />
        </div>
        <Cabinet
          nameUser={this.state.nameUser}
          phoneUser={this.state.phoneUser}
          cardUser={this.state.cardUser}
          UserID={this.state.UserID}
          getStat={this.getStat}
          getAllrefLid={this.getAllrefLid}
          createNewLid={this.createNewLid}
        />
        <Footer />
        {this.enterModal()}
      </div >
    );
  }
}

export default App;
